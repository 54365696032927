import {GenerationTaskSubsystemType} from '@generation-api-v2/util';

export const ROUTES = {
  projects: 'projects',
  map: 'map',
  portfolio: 'portfolio',
  notFound: '404',
  readOnly: 'read-only',
};

export const SUBSYSTEM_ROUTE: {[key in GenerationTaskSubsystemType]: string} = {
  LayoutSections: 'layout-sections',
  Layout: 'layout',
  Structure: 'structure',
  Structure2: 'structure-2',
  Mesh: 'structure',
  MEP: 'mep',
  HVAC: 'hvac',
  HVAC2: 'hvac-2',
  Plumbing: 'plumbing',
  Plumbing2: 'plumbing-2',
  Electrical: 'electrical',
  Electrical2: 'electrical-2',
  SpacePlanning: 'spaceplanning',
  OMAR: 'architecture',
  OMAR2: 'architecture-2',
  OMAR3: 'architecture-3',
  Final: 'final',
};
